import {getCountries} from "@/api/api";

export default {
    watch: {
        '$i18n.locale'() {
            // 检查是否已经加载过国家列表，如果是，则不重新加载
            if (this.countryOptions.length > 0) {
                this.translateCountryOptions();
                return;
            }

            this.fetchCountryOptions();
        },
    },
    data() {
        return {
            countryOptions: [],
            countriesLoaded: false, // 新增字段用于标识国家列表是否已加载
        };
    },

    methods: {
        async fetchCountryOptions() {
            if (!this.countriesLoaded) {
                try {
                    // 调用后端接口获取国家列表数据
                    const response = await getCountries(); // 假设 getCountries() 函数是从后端获取国家列表的方法
                    // 将后端返回的国家列表数据转换为前端需要的格式
                    this.countryOptions = response.data.map(country => ({
                        value: country.id,
                        code: country.code,
                        label: this.$t(`countries.${country.code}`),
                    }));
                    this.countriesLoaded = true; // 标记国家列表已加载
                } catch (error) {
                    console.error('Error fetching country options:', error);
                }
            }
        },
        translateCountryOptions() {
            // 仅在语言改变时重新翻译国家列表的标签
            this.countryOptions.forEach(option => {
                option.label = this.$t(`countries.${option.code}`); // 假设country.id或country.code可作为国际化键
            });
        },
    },
};