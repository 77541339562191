import {createStore} from 'vuex';
import api from "@/views/ProductUpgrade/api";
import {compareversions} from "@/utils/common";

export default createStore({
    state: {
        currentLanguage: localStorage.getItem('currentLanguage') || 'zh', // 如果没有设置，默认为中文
        languageFriendlyName: localStorage.getItem('languageFriendlyName') || '简体中文', // 默认语言友好名称
        motorInfo: null,
        batteryInfo: null,
        displayInfo: null,
        motorConfigData: null,
        batteryDetailData: null,
        motorConfigData1: null,
        displayConfigData: null,
        motorInfoRealTimeData: null,
        displayInfoRealTimeData: null,

        motorSelfErrorData: null,
        motorHistoryError: null,

        isMotorConnected: false,
        isDisplayConnected: false,
        ws: null,

        isMotorConnecting: false,
        isBatteryConnecting: false,
        isDisplayConnecting: false,

        pluginStatus: 0, // 0: 正常, 1: 未安装, 2: 未升级
        pluginUrl: '',
    },
    mutations: {
        setPluginUrl(state, url) {
            state.pluginUrl = url;
        },
        setPluginStatus(state, value) {
            state.pluginStatus = value;
        },
        setLanguage(state,  { language, friendlyName }) {
            state.currentLanguage = language;
            state.languageFriendlyName = friendlyName;
            localStorage.setItem('currentLanguage', language);
            localStorage.setItem('languageFriendlyName', friendlyName);
        },
        setMotorInfo(state, motorInfo) {
            state.motorInfo = motorInfo;
        },
        setBatteryInfo(state, batteryInfo) {
            state.batteryInfo = batteryInfo;
        },
        setDisplayInfo(state, displayInfo) {
            state.displayInfo = displayInfo;
        },
        setMotorConfigData(state, motorConfigData) {
            state.motorConfigData = motorConfigData;
        },
        setBatteryDetailData(state, batteryDetailData) {
            state.batteryDetailData = batteryDetailData;
        },
        setMotorConfigData1(state, motorConfigData1) {
            state.motorConfigData1 = motorConfigData1;
        },
        setDisplayConfigData(state, displayConfigData) {
            state.displayConfigData = displayConfigData;
        },
        setMotorRealTimeData(state, motorInfoRealTimeData) {
            state.motorInfoRealTimeData = motorInfoRealTimeData;
        },
        setDisplayRealTimeData(state, displayInfoRealTimeData) {
            state.displayInfoRealTimeData = displayInfoRealTimeData;
        },
        setMotorConnected(state, isConnected) {
            state.isMotorConnected = isConnected;
        },
        setDisplayConnected(state, isDisplayConnected) {
            state.isDisplayConnected = isDisplayConnected;
        },
        setMotorHistoryError(state, payload) {
            state.motorHistoryError = payload;
        },
        // 用于更新电机自检错误信息的 mutation
        setMotorSelfErrorData(state, data) {
            state.motorSelfErrorData = data;
        },
        clearMotorState(state) {
            state.isMotorConnected = false;
            state.motorInfo = null;
            state.motorConfigData = null;
            state.motorInfoRealTimeData = null;
            state.motorSelfErrorData = null;
            state.motorHistoryError = null;
        },
        clearBatteryState(state) {
            state.batteryInfo = {};
            state.batteryDetailData = {};
        },
        clearDisplayState(state) {
            state.isDisplayConnected = false;
            state.displayInfo = null;
            state.displayConfigData = null;
            state.displayInfoRealTimeData = null;
        },
        setWebSocket(state, ws) {
            // 设置WebSocket连接
            if (state.ws && state.ws.readyState === WebSocket.OPEN) {
                state.ws.close(); // 如果存在旧的连接，则先关闭
            }
            state.ws = ws;
        },

        closeWebSocket(state) {
            // 关闭WebSocket连接
            if (state.ws && state.ws.readyState !== WebSocket.CLOSED) {
                state.ws.close(); // 如果WebSocket连接存在且未关闭，则关闭连接
            }
            state.ws = null; // 将state中的ws置为null
        },
        setIsMotorConnecting(state, status) {
            state.isMotorConnecting = status;
        },
        setIsBatteryConnecting(state, status) {
            state.isBatteryConnecting = status;
        },
        setIsDisplayConnecting(state, status) {
            state.isDisplayConnecting = status;
        },
        // 设置所有设备为离线状态
        setAllDevicesOffline(state) {
            state.isMotorConnected = false;
            state.isDisplayConnected = false;
            state.isBatteryConnecting = false;
        },

        // 清空所有设备的数据
        clearAllDeviceData(state) {
            state.motorInfo = null;
            state.batteryInfo = null;
            state.displayInfo = null;
            state.motorConfigData = null;
            state.batteryDetailData = null;
            state.motorConfigData1 = null;
            state.displayConfigData = null;
            state.motorInfoRealTimeData = null;
            state.displayInfoRealTimeData = null;
            state.motorSelfErrorData = null;
            state.motorHistoryError = null;
        },

    },

    actions: {
        changeLanguage({commit}, { language, friendlyName }) {
            commit('setLanguage',  { language, friendlyName });
        },
        getMotorInfoHistoryErrorData({commit}, motorHistoryError) {
            commit('setMotorHistoryError', motorHistoryError);
        },
        // 调用这个 action 来处理获取电机自检错误信息，并存储
        getMotorSelfErrorData({commit}, data) {
            // 可以在这里添加额外的逻辑处理
            commit('setMotorSelfErrorData', data);
        },
        fetchPluginVersion({commit}) {
            api.pluginVersion().then(res => {
                commit('setPluginUrl', res.file_url);
                commit('setPluginStatus', 1);
            }).catch(error => {
                console.error('获取插件版本失败，请稍后重试');
                commit('setPluginStatus', 0);
                throw error;
            });
        },

        checkPluginVersionAndUpdate({commit}, currentVersion) {
            api.pluginVersion().then(res => {
                if (compareversions(currentVersion, res.version) === -1) {

                    commit('setPluginUrl', res.file_url);
                    commit('setPluginStatus', 2);
                } else {
                    commit('setPluginStatus', 0);
                }
            }).catch(error => {
                console.error('获取插件版本失败，请稍后重试');
                commit('setPluginStatus', 0);
                throw error;
            });
        },
    },
    getters: {
        currentLanguage: (state) => state.currentLanguage,
        currentLanguageFriendlyName: state => state.languageFriendlyName,
        motorInfo: (state) => state.motorInfo,
        batteryInfo: (state) => state.batteryInfo,
        displayInfo: (state) => state.displayInfo,
        motorConfigData: (state) => state.motorConfigData,
        batteryDetailData: (state) => state.batteryDetailData,
        motorConfigData1: (state) => state.motorConfigData1,
        displayConfigData: (state) => state.displayConfigData,
        motorInfoRealTimeData: (state) => state.motorInfoRealTimeData,
        displayInfoRealTimeData: (state) => state.displayInfoRealTimeData,

        isMotorConnected: (state) => state.isMotorConnected,
        isDisplayConnected: (state) => state.isDisplayConnected,

        motorSelfErrorData: (state) => state.motorSelfErrorData,
        motorHistoryError: (state) => state.motorHistoryError,
    },
});
