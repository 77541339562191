import dayjs from 'dayjs'

/**
 * @desc  格式化时间
 * @param {(Object|string|number)} time
 * @param {string} format
 * @returns {string | null}
 */
export function formatDateTime(time = undefined, format = 'YYYY-MM-DD HH:mm:ss') {
  return dayjs(time).format(format)
}

export function formatDate(date = undefined, format = 'YYYY-MM-DD') {
  return formatDateTime(date, format)
}

export function compareversions(version1,version2)
{
    const arr1 = version1.split('.');
    const arr2 = version2.split('.');
    let result = 0;
    for (let i = 0;i<arr1.length; i+= 1){
      if(parseInt(arr1[i])>parseInt(arr2[i])){
          result = 1;
          break;
      }else if(parseInt(arr1[i])<parseInt(arr2[i])){
          result = -1;
          break;
      }
    }
    if(result === 0 && arr1.length !== arr2.length){
    result = arr1.length >arr2.length ?1:-1;
    }
return result;
}