import {createRouter, createWebHistory} from 'vue-router';
import i18n from '@/i18n';

const routes = [
    {
        path: '/',
        redirect: '/ProductUpgrade',
        meta: {title: 'ProductUpgrade.productUpgrade',permission:'productUpgrade'},
    },
    {
        path: '/AfterSales',
        component: () => import('@/views/AfterSales/AllPage.vue'),
        props: true,
        meta: {title: 'ApplyAfterSales.applyAfterSales',permission:'applyAfterSale'},
    },
    {
        path: '/FormPage', // 新页面的路径
        component: () => import('@/views/AfterSales/applyAfterSalesAndsubFunc/FormPage.vue'), // 表单页面组件
        props: true,
        meta: {title: 'ApplyAfterSales.applyAfterSales',permission:'applyAfterSale'},
    },
    {
        path: '/EditTask/:taskId', // 新页面的路径
        name: 'EditTask',
        component: () => import('@/views/AfterSales/applyAfterSalesAndsubFunc/editTask.vue'), // 表单页面组件
        props: true,
        meta: {title: 'ApplyAfterSales.applyAfterSales',permission:'applyAfterSale'},
    },
    //申请售后模块,点击下一步后的页面
    {
        path: '/FaultPage/:taskId',
        name: 'FaultPage',
        component: () => import('@/views/AfterSales/editTask/FaultPage.vue'),
        props: true,
        meta: {title: 'ApplyAfterSales.applyAfterSales',permission:'applyAfterSale'},
    },
    {
        path: '/ClientDetail/:taskId',
        name: 'ClientDetail',
        component: () => import('@/views/AfterSales/applyAfterSalesAndsubFunc/ClientDetail.vue'),
        props: true,
        meta: {title: 'ApplyAfterSales.applyAfterSales',permission:'applyAfterSale'},
    },
    //产品诊断页面
    {
        path: '/ProductDiagnosis',
        name: 'ProductDiagnosis',
        component: () => import('@/views/ProductDiagnosis/ProductDiagnosis.vue'),
        props: true,
        meta: {title: 'ProductDiagnosis.productDiagnosis',permission:'productDiagnose'},

    },
    //售后处理页面
    {
        path: '/ServiceCenter',
        name: 'ServiceCenter',
        component: () => import('@/views/ServiceCenter/ServiceCenter.vue'),
        props: true,
        meta: {title: 'ProcessAfterSale.processAfterSale',permission:'dealAfterSale'},
    },
    //产品管理的型号管理页面
    {
        path: '/ModelManagement',
        name: 'ModelManagement',
        component: () => import('@/views/ProductManage/ModelManagement/ModelManagement.vue'),
        props: true,
        meta: {title: 'ProductManage.ModelManage.modelManage',permission:'modelManage'},
    },
    //产品管理的配置管理页面
    {
        path: '/ConfigManagement',
        name: 'ConfigManagement',
        component: () => import('@/views/ProductManage/ConfigManagement/ConfigManagement.vue'),
        props: true,
        meta: {title: 'ProductManage.ConfigManage.configManage',permission:'configMange'},
    },

    //历史数据的诊断报告页面
    {
        path: '/DiagnosisReport',
        name: 'DiagnosisReport',
        component: () => import('@/views/HistoricalData/DiagnosisReport/DiagnosisReport.vue'),
        props: true,
        meta: {title: 'HistoryData.diagnosisReport.diagnosisReport',permission:'diagnoseReport'},

    },
    //历史数据的升级记录页面
    {
        path: '/UpgradeRecords',
        name: 'UpgradeRecords',
        component: () => import('@/views/HistoricalData/UpgradeRecords/UpgradeRecords.vue'),
        props: true,
        meta: {title: 'HistoryData.UpdateRecord.updateRecord',permission:'upgradeRecord'},
    },
    //历史数据的客户反馈页面
    {
        path: '/FeedbackPage',
        name: 'FeedbackPage',
        component: () => import('@/views/HistoricalData/FeedbackPage/FeedbackPage.vue'),
        props: true,
        meta: {title: 'HistoryData.CustomerFeedback.customerFeedback',permission:'customerFeedback'},

    },
    //用户管理的角色管理页面
    {
        path: '/RoleManagement',
        name: 'RoleManagement',
        component: () => import('@/views/UserManagement/RoleManagement/indexItem.vue'),
        props: true,
        meta: {title: 'userManage.roleManagement',permission:'roleManage'},

    },
    //用户管理的自注册账号管理页面
    {
        path: '/SelfRegistration',
        name: 'SelfRegistration',
        component: () => import('@/views/UserManagement/SelfRegistration/SelfRegistration.vue'),
        props: true,
        meta: {title: 'userManage.selfRegAccountManagement',permission:'selfRegister'},

    },
    //用户管理的组织管理页面
    {
        path: '/OrganizationManagement',
        name: 'OrganizationManagement',
        component: () => import('@/views/UserManagement/OrganizationManagement/OrganizationManagement.vue'),
        props: true,
        meta: {title: 'userManage.organizationManagement',permission:'organization'},

    },
    //FAQ管理页面
    {
        path: '/FAQManagement',
        name: 'FAQManagement',
        component: () => import('@/views/FAQManagement/FAQManagement.vue'),
        meta: {fromTopMenu: false, title: 'quickDiagnosis.quickDiagnosisManagement',permission:'faq'}, // 添加这行
        props: true,
    },
    // 顶部菜单的快捷诊断
    {
        path: '/QuickDiagnosis',
        name: 'QuickDiagnosis',
        component: () => import('@/views/FAQManagement/FAQManagement.vue'),
        meta: {
            fromTopMenu: true,  // 特别标记为从顶部菜单触发的
            title: 'quickDiagnosis.quickDiagnosisManagement',permission:'faq'

        },
        props: true,
    },
    //产品升级页面
    {
        path: '/ProductUpgrade',
        name: 'ProductUpgrade',
        component: () => import('@/views/ProductUpgrade/indexItem.vue'),
        props: true,
        meta: {title: 'ProductUpgrade.productUpgrade',permission:'productUpgrade'},
    },
    //售后处理页面的详情功能跳转页面
    {
        path: '/DetailsPage/:taskId',
        name: 'DetailsPage',
        component: () => import('@/views/ServiceCenter/components/DetailsPage.vue'),
        props: true,
        meta: {title: 'ProcessAfterSale.processAfterSale',permission:'dealAfterSale'},
    },
    //顶部导航栏的头像的设置跳转页面
    {
        path: '/SettingsPage',
        name: 'SettingsPage',
        component: () => import('@/components/NavigationBarComponents/SettingsPage.vue'),
        props: true,
        meta: {title: ''},
    },
    //登录页面
    {
        path: '/LoginPage',
        name: 'LoginPage',
        component: () => import('@/views/LoginPage/loginPage.vue'),
        meta: {public: true, hideLayout: true, title: ''} // 添加这行来标记登录页面为公共页面
    },
    // 详情页面的路由配置
    /*{
        path: '/Details/:id?', // 动态路由，:id 表示详情的ID
        name: 'Details',
        component: () => import('@/views/FAQManagement/components/contentComponents/DetailsAnotherPage.vue'),
        props: true, // 允许通过路由传递 props
        meta: {title: 'quickDiagnosis.quickDiagnosisManagement',permission:'faq'},
    },*/
    {
        path: '/Details',
        name: 'Details',
        component: () => import('@/views/FAQManagement/components/contentComponents/DetailsAnotherPage.vue'),
        props: route => ({ id: route.query.id }), // 通过查询参数传递 ID
        meta: { title: 'quickDiagnosis.quickDiagnosisManagement', permission: 'faq' }
    },

    {
        path: '/application/problemDetail',
        name: 'FaqDetailHiddenMenu',
        component: () => import('@/views/FAQManagement/components/contentComponents/DetailsAnotherPage.vue'),
        props: route => ({ id: route.query.id }), // 通过查询参数传递 ID
        meta: { hideLayout: true, hideBackButton: true, title: '', permission: 'faq' }
    },
    // 编辑页面的路由配置
    {
        path: '/EditFAQ/:id?', // 动态路由，:id 表示详情的ID
        name: 'EditFAQ',
        component: () => import('@/views/FAQManagement/components/contentComponents/EditFAQ.vue'),
        props: true, // 允许通过路由传递 props
        meta: {title: 'quickDiagnosis.quickDiagnosisManagement',permission:'faq'},
    },
    {
        path: '/AddFAQ/:selectedNodeId', // 动态路由，:id 表示详情的ID
        name: 'AddFAQ',
        component: () => import('@/views/FAQManagement/components/contentComponents/AddFAQ.vue'),
        props: true, // 允许通过路由传递 props
        meta: {title: 'quickDiagnosis.quickDiagnosisManagement',permission:'faq'},
    },
    {
        path: '/ReportView',
        name: 'ReportView',
        component: () => import('@/views/ProductDiagnosis/components/ReportView.vue'),
        props: true,
        meta: {title: '',permission:'productDiagnose'},
    },
    {
        path: '/tools/ws',
        name: 'websocket测试',
        component: () => import('@/views/Tools/WsIndex.vue'),
        meta: {title: 'websocket测试',permission:'toolsWs'}
    },
    {
        path: '/tools/plugin',
        name: '插件管理',
        component: () => import('@/views/Tools/PluginIndex.vue'),
        meta: {title: '插件管理',permission:'toolsPlugin'}
    },
    {
        path: '/403',
        name: '403',
        component: () => import('@/views/403.vue'),
        meta: { public: true, title: '403' }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// 递归检查权限
const hasPermission = (userPermissions, permission) => {
    return userPermissions.some(p => {
        if (p.code === permission) {
            return true;
        }
        if (p.children && p.children.length > 0) {
            return hasPermission(p.children, permission);
        }
        return false;
    });
};

// 添加路由守卫
router.beforeEach((to, from, next) => {
    /* 检查登录状态的逻辑 */
    const isLoggedIn = localStorage.getItem('token') !== null;
    const userId = localStorage.getItem('userId'); // 从 localStorage 中获取 userId
    const cacheKey = `userPermissions_${userId}`;
    const userPermissions = JSON.parse(localStorage.getItem(cacheKey)) || [];
    const isPublicPage = to.matched.some(record => record.meta.public);
    if (!isLoggedIn && !isPublicPage) {
        localStorage.setItem('redirectAfterLogin', to.fullPath); // 保存用户尝试访问的地址
        // 用户未登录且试图访问非公共页面
        next({path: '/LoginPage'}); // 重定向到登录页面
    } else if (isLoggedIn && !isPublicPage) {
        const requiredPermission = to.meta.permission;
        if (requiredPermission && !hasPermission(userPermissions, requiredPermission)) {
            // 如果用户没有访问该页面的权限
            next({path: '/403'}); // 阻止导航
        } else {
            next(); // 正常继续
        }
    } else {
        // 用户已登录或访问公共页面
        next(); // 正常继续
    }
});

router.afterEach((to) => {
    const titleKey = to.meta.title; // 获取路由元信息中的标题键
    let translatedTitle = '';
    try {
        translatedTitle = i18n.global.t(titleKey);
    } catch (e) {
        console.error('Error translating titleKey:', titleKey, e); // 打印错误信息
    }

    const appTitle = i18n.global.t('appTitle'); // 获取应用的翻译标题
    document.title = translatedTitle ? `${translatedTitle} - ${appTitle}` : appTitle;
});

export default router;