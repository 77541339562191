import i18nIsoCountries from 'i18n-iso-countries';
import store from "@/store";
// 在mixin.js文件顶部导入事件总线
import EventBus from '@/eventBus';
import api from "@/views/ProductUpgrade/api";

export const pluginStatusMixin = {
    data() {
        return {
            preservedRoutes: ['ProductDiagnosis', 'ProductUpgrade'],
            isStayPage: true,
            pluginVersion: null,  // 新增变量
        }
    },
    unmounted() {
        this.isStayPage = false;
        store.commit('closeWebSocket'); // 组件卸载时关闭WebSocket
    },
    methods: {
        async checkPluginStatus() {
            try {
                const currentRoute = this.$route;
                if (this.preservedRoutes.includes(currentRoute.name)) {
                    // 如果WebSocket已经打开且连接状态不是CLOSED，则关闭它
                    if (this.ws && this.ws.readyState !== WebSocket.OPEN) {
                        this.ws.close();
                    }
                    // 建立 WebSocket 连接并设置一次消息处理程序
                    this.ws = new WebSocket('ws://127.0.0.1:1234');
                    // 修改后的部分代码
                    this.$store.commit('setWebSocket', this.ws);
                    this.ws.onopen = () => {
                        // 设置 isMotorConnecting 为 true
                        this.$store.commit('setIsMotorConnecting', true);
                        this.$store.commit('setIsBatteryConnecting', true);
                        this.$store.commit('setIsDisplayConnecting', true);
                        // 发送第一个指令
                        this.sendInstruction({cmd: 'getExeVer', param: ''});
                    };
                    this.ws.onclose = () => {
                        // 设置所有设备为离线并清空数据
                        this.$store.commit('setAllDevicesOffline');
                        this.$store.commit('clearAllDeviceData');
                    };
                    this.ws.onerror = (error) => {
                        console.error('WebSocket error:', error);
                        console.error('插件未运行');
                        // 设置所有设备为离线并清空数据
                        this.$store.commit('setAllDevicesOffline');
                        this.$store.commit('clearAllDeviceData');

                        if (this.isStayPage) {
                            // 直接调用 fetchPluginVersion action
                            this.$store.dispatch('fetchPluginVersion');
                        } else {
                            this.$store.commit('setPluginStatus', 0);
                        }
                    };
                    this.ws.onmessage = (event) => {
                        try {
                            const message = JSON.parse(event.data);
                            this.handleWebSocketMessage(message, this.ws);
                        } catch (error) {
                            console.error("Error handling WebSocket message:", error);
                        }
                    };

                    store.commit('setWebSocket', this.ws); // 通过Vuex设置WebSocket
                } else {
                    store.commit('closeWebSocket'); // 不在保留路由列表时关闭WebSocket
                    //断开webSocket连接
                    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                        this.ws.close();
                    }
                }
            } catch (error) {
                console.error('Error checking plugin status:', error);
            }
        },

        // 发送指令方法
        sendInstruction(instruction) {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                this.ws.send(JSON.stringify(instruction));
            }
        },

        closePort() {
            return new Promise((resolve, reject) => {
                if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                    const closePortCommand = {"cmd": "closePort", "param": "0"};
                    this.ws.send(JSON.stringify(closePortCommand));

                    // 设置一个超时等待服务器响应
                    const waitForResponse = setTimeout(() => {
                        reject(new Error('等待关闭串口响应超时'));
                    }, 3000); // 等待5秒

                    // 临时覆盖onmessage以处理关闭串口响应
                    const originalOnMessage = this.ws.onmessage;
                    this.ws.onmessage = (event) => {
                        const message = JSON.parse(event.data);
                        if (message.cmd === 'closePort') {
                            clearTimeout(waitForResponse);
                            this.ws.onmessage = originalOnMessage; // 恢复原始处理程序
                            if (message.code === 200) {
                                // 清空状态
                                this.$store.commit('clearMotorState');
                                this.$store.commit('clearBatteryState');
                                this.$store.commit('clearDisplayState');
                                resolve();
                            } else {
                                console.error('关闭串口失败：', message.message);
                                reject(new Error('关闭串口失败: ' + message.message));
                            }
                        } else {
                            originalOnMessage(event); // 对其他消息调用原始处理程序
                        }
                    };
                } else {
                    reject(new Error('WebSocket未打开'));
                }
            });
        },


// 发送连接电机的命令
        connectMotor() {
            const connectMotorCommand = {
                cmd: 'connectMotor', "timeout": "0"
            };
            this.ws.send(JSON.stringify(connectMotorCommand));
        },

        async connectDisplay() {
            setTimeout(() => {
                const connectDisplayCommand = {
                    cmd: 'connectDisplay', timeout: '0'
                };
                this.ws.send(JSON.stringify(connectDisplayCommand));
            }, 1000); // 延迟1秒发送连接仪表的命令
        },

// 处理 WebSocket 消息
        async handleWebSocketMessage(message) {
            // 在 mixin.js 的 handleWebSocketMessage 方法内
            if (message.cmd === "getAppDataPath" || message.cmd === "UpdateDevice" || message.cmd === "UpdateDeviceProgress" || message.cmd === "getMotorInfo_boot" || message.cmd === "downloadfile") {
                // 根据消息类型，触发不同的事件
                EventBus.emit('messageReceived', message);
            } else if (message.cmd === "startMotorAutoTest") {
                // 新增处理startMotorAutoTest命令的逻辑
                EventBus.emit('startMotorAutoTestResponse', message);
            } else if (message.cmd === "startMotorTest" || message.cmd === "getMotorInfo_RealTime" || message.cmd === "stopMotorTest") {
                EventBus.emit('RealTimeWsMessage', message);
            } else if (message.cmd === "getBatteryInfo_RealTime") {
                EventBus.emit('BatteryRealTimeWsMessage', message);
            } else if (message.cmd === "getDisplayInfo_Config") {
                EventBus.emit('DisplayInfoResponse', message);
            } else if (message.cmd === "getMotorInfo_HisError") {
                EventBus.emit('HisErrorResponse', message);
            } else if (message.cmd === "getMotorInfo_SelfError") {
                EventBus.emit('SelfErrorResponse', message);
            } else if (message.cmd === "DeviceTestCancel") {
                EventBus.emit('DeviceTestCancelReceived', message);
            } else if (message.cmd === "openLight") {
                // 同样，触发一个事件
                EventBus.emit('OpenLightResponse', message);
            } else if (message.cmd === "closeLight") {
                // 触发关灯指令的事件
                EventBus.emit('CloseLightResponse', message);
            } else if (message.cmd === "DeviceTest") {
                // 特定测试命令处理，此处需要添加itemid的检查，假设message对象里有itemid属性
                if (message.data) {
                    try {
                        const data = JSON.parse(message.data);
                        // 使用cmd和itemid的组合作为事件名称
                        const eventName = `DeviceTest_${data.itemid}`;
                        EventBus.emit(eventName, message);
                    } catch (error) {
                        console.error("JSON.parse error:", error);
                    }
                } else {
                    console.error("message.data is empty or undefined");
                }
            } else {
                // 调用后端接口获取参数
                if (message.cmd === 'getExeVer' && message.code === 200) {
                    this.pluginVersion = message.data;
                    // 收到第一个指令的响应后再发送第二个和第三个指令
                    this.$store.dispatch('checkPluginVersionAndUpdate', message.data);
                    api.hardwareProtocol().then(res => {
                        // 使用结果发送指令
                        this.sendInstruction({cmd: 'updateCanList', param: res.can || 'defaultCanData'});
                        this.sendInstruction({cmd: 'updateUartList', param: res.uart || 'defaultUartData'});
                    }).catch(error => {
                        console.error("Error during API call:", error);
                    });

                } else if (message.cmd === 'updateUartList' && message.code === 200) {
                    // 收到第二个指令的响应后再发送关闭串口命令，然后连接电机
                    this.closePort()
                        .then(() => {
                            this.connectMotor();
                        })
                        .catch(error => {
                            console.error('Error closing port before connecting motor:', error);
                            // 即使关闭串口失败，也尝试连接电机
                            this.connectMotor();
                        });
                } else if (message.cmd === 'closePort') {
                    if (message.code !== 200) {
                        console.error('关闭串口失败：', message.message);
                    }
                }
                switch (message.cmd) {
                    case 'connectMotor':
                        await this.handleConnectMotorMessage(message);
                        break;
                    case 'getMotorInfo':
                        this.handleGetMotorInfoMessage(message);
                        break;
                    case 'getBatteryInfo':
                        await this.handleGetBatteryInfoMessage(message);
                        break;
                    case 'getDisplayInfo':
                        this.handleGetDisplayInfoMessage(message);
                        break;
                    case 'getBatteryInfo_Detail':
                        this.handleGetBatteryDetailMessage(message);
                        break;
                    case 'getMotorInfo_Config':
                        this.handleGetMotorConfigMessage(message);
                        break;
                    case 'getMotorInfo_Config1':
                        this.handleGetMotorConfig1Message(message);
                        break;
                    case 'connectDisplay':
                        await this.handleConnectDisplayMessage(message);
                        break;
                    case 'getDisplayInfo_RealTimeDatas':
                        this.handleGetDisplayRealTimeMessage(message);
                        break;

                    case 'closePort':
                        if (message.code !== 200) {
                            console.error('关闭串口失败：', message.message);
                        }
                        break;
                }
            }
        },

// 处理连接电机消息
        async handleConnectMotorMessage(message) {
            const connectRecordData = {
                type: 2,
                cmd: message.cmd,
                code: message.code,
                message: message.message,
                data: message.data,
                plugin_version: this.pluginVersion,
            };
            await api.addConnectRecord(connectRecordData ).catch(error => {
                console.error("Error adding connect record:", error);
            });
            if (message.code === 200) {
                // 连接成功，设置 isConnecting 为 false
                store.commit('setIsMotorConnecting', false);
                store.commit('setMotorConnected', true);
                store.commit('setIsBatteryConnecting', true);
                store.commit('setIsDisplayConnecting', true);
                // 连接成功后发送获取电机信息的命令
                this.fetchMotorInfo(this.ws);
                this.fetchConfigData(this.ws);
                this.fetchBatteryInfo(this.ws);
            } else if (message.code === 404) {
                // 特定错误码处理，显示无可用COM口连接
                this.$message.error(this.$t('ProductUpgrade.connectionFailedNoCOMPort'));
                store.commit('setIsMotorConnecting', false);
                store.commit('setIsBatteryConnecting', false);
                store.commit('setMotorConnected', false); // 显式设置为连接失败
                store.commit('setIsDisplayConnecting', false);
                store.commit('setDisplayConnected', false); // 显式设置为连接失败
                store.commit('setBatteryInfo', null);
            } else {
                // 连接失败，也应设置 isConnecting 为 false
                store.commit('setIsMotorConnecting', false);
                store.commit('setIsBatteryConnecting', true);
                store.commit('setMotorConnected', false); // 如果连接失败或断开
                store.commit('setBatteryInfo', null);
                store.commit('setIsDisplayConnecting', true);
                await this.connectDisplay();

            }
        },

        async handleConnectDisplayMessage(message) {
            const connectRecordData = {
                type: 4,
                cmd: message.cmd,
                code: message.code,
                message: message.message,
                data: message.data,
                plugin_version: this.pluginVersion,
            };
            await api.addConnectRecord(connectRecordData).catch(error => {
                console.error("Error adding connect record:", error);
            });
            if (message.code === 200) {
                store.commit('setIsDisplayConnecting', false);
                store.commit('setDisplayConnected', true);
                store.commit('setIsMotorConnecting', false);
                store.commit('setIsBatteryConnecting', false);
                this.fetchDisplayInfo(this.ws);
            } else if (message.code === 404) {
                // 特定错误码处理，显示无可用COM口连接
                this.$message.error(this.$t('ProductUpgrade.connectionFailedNoCOMPort'));
                store.commit('setIsDisplayConnecting', false);
                store.commit('setIsMotorConnecting', false);
                store.commit('setIsBatteryConnecting', false);
                store.commit('setDisplayConnected', false); // 显式设置为连接失败
            } else {
                store.commit('setIsDisplayConnecting', false);
                store.commit('setIsMotorConnecting', false);
                store.commit('setIsBatteryConnecting', false);
                store.commit('setDisplayConnected', false); // 如果连接失败或断开

            }
        },

// 发送获取电机信息的命令
        fetchMotorInfo(ws) {
            const getMotorInfoCommand = {
                cmd: 'getMotorInfo', param: ''
            };
            ws.send(JSON.stringify(getMotorInfoCommand));
        },


        fetchDisplayInfo(ws) {
            const getDisplayInfoCommand = {
                cmd: 'getDisplayInfo', param: ''
            };
            ws.send(JSON.stringify(getDisplayInfoCommand));
        },

        fetchBatteryInfo(ws) {
            const getBatteryInfoCommand = {
                cmd: 'getBatteryInfo', param: ''
            };
            ws.send(JSON.stringify(getBatteryInfoCommand));
        },

        fetchBatteryInfoDetailData(ws) {
            const getBatteryInfoDetailCommand = {
                cmd: 'getBatteryInfo_Detail', param: ''
            };
            ws.send(JSON.stringify(getBatteryInfoDetailCommand));
        },

// 处理获取电机信息消息
        handleGetMotorInfoMessage(message) {
            if (message.code === 200) {
                // 将电机信息提交到 Vuex store 中
                store.commit('setMotorInfo', message.data);
            }
        },

        async handleGetBatteryInfoMessage(message) {
            const connectRecordData = {
                type: 3,
                cmd: message.cmd,
                code: message.code,
                message: message.message,
                data: message.data,
                plugin_version: this.pluginVersion,
            };
            await api.addConnectRecord( connectRecordData ).catch(error => {
                console.error("Error adding connect record:", error);
            });
            if (message.code === 200) {
                store.commit('setIsBatteryConnecting', false);
                store.commit('setIsDisplayConnecting', true);
                store.commit('setIsMotorConnecting', false);
                // 将电机信息提交到 Vuex store 中
                store.commit('setBatteryInfo', message.data);
                // 成功获取电池信息后，接下来连接仪表
                await this.connectDisplay();
            } else {
                store.commit('setIsBatteryConnecting', false);
                store.commit('setIsDisplayConnecting', true);
                store.commit('setIsMotorConnecting', false);
                store.commit('setBatteryInfo', null);
                // 成功获取电池信息后，接下来连接仪表
                await this.connectDisplay();
            }
        },

        handleGetDisplayInfoMessage(message) {
            if (message.code === 200) {
                // 将电机信息提交到 Vuex store 中
                store.commit('setDisplayInfo', message.data);
            }
        },

// 发送获取电机配置信息的命令
        fetchConfigData(ws) {
            const getMotorConfigCommand = {
                cmd: 'getMotorInfo_Config', param: ''
            };
            ws.send(JSON.stringify(getMotorConfigCommand));
        },

// 处理获取电机配置信息消息
        handleGetMotorConfigMessage(message) {
            if (message.code === 200) {
                // 将电机配置信息提交到 Vuex store 中
                store.commit('setMotorConfigData', message.data);
            }
        },
        handleGetBatteryDetailMessage(message) {
            if (message.code === 200) {
                // 将电机配置信息提交到 Vuex store 中
                store.commit('setBatteryDetailData', message.data);
            }
        },

        handleGetMotorConfig1Message(message) {
            if (message.code === 200) {
                //console.debug('收到电机配置信息1：', message.data);
                // 将电机配置信息1提交到 Vuex store 中
                store.commit('setMotorConfigData1', message.data);
            }
        },

        handleGetDisplayRealTimeMessage(message) {
            if (message.code === 200) {
                // 将电机实时信息提交到 Vuex store 中
                store.commit('setDisplayRealTimeData', message.data);
            }
        },


    },
};

/**
 *
 * @returns {{label: *, value: *}[]}
 */

export function getCountryOptions(currentLang) {
    // 根据当前语言动态注册语言包
    switch (currentLang) {

        case 'en':
            i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
            break;
        case 'fr':
            i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
            break;
        case 'de':
            i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));
            break;
        case 'nl':
            i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/nl.json'));
            break;
        case 'sv':
            i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/sv.json'));
            break;
        case 'pl': // 添加波兰语支持
            i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/pl.json'));
            break;
        case 'hu':
            i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/hu.json'));
            break;
        case 'ja':
            i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/ja.json'));
            break;
        case 'it':
            i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/it.json'));
            break;
        case 'zh':
            i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/zh.json'));
            break;
        default:
            i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/zh.json'));
    }

    // 获取并返回国家列表
    return Object.entries(i18nIsoCountries.getNames(currentLang)).map(([code, label]) => ({
        value: code, label,
    }));
}

/**
 * 协议类型转换
 */
export default {
    methods: {
        mapProtocolTypeToString(protocolType) {
            switch (protocolType) {
                case 0:
                    return 'UART';
                case 1:
                    return 'CAN';
                default:
                    return 'Unknown';
            }
        },
        mapMotorTypeToString(motorType) {
            switch (motorType) {
                case 0:
                    return 'mid';
                case 1:
                    return 'hub';
                default:
                    return 'Unknown';
            }
        },
    },
};