export default {
    data() {
        return {
            subPermissions: [],
            isButtonLoading: false,  // 按钮的加载状态
        };
    },
    computed: {
        dynamicDialogWidth() {
            const screenWidth = window.innerWidth;
            if (screenWidth < 576) {
                return '80%';
            } else if (screenWidth < 992) {
                return '60%';
            } else if (screenWidth < 1550) {
                return '50%'; // 你可以根据需要调整这个宽度
            } else {
                return '20%';
            }
        },
    },
    methods: {
        async handleButtonAction(action) {
            this.isButtonLoading = true;  // 开启加载
            try {
                await action();  // 执行传入的函数
            } catch (error) {
                console.log(error);  // 统一的错误处理逻辑
            } finally {
                this.isButtonLoading = false;  // 完成后关闭加载
            }
        },
        mapProcessingProgress(progress) {
            switch (progress) {
                case 0:
                    return this.$t('ProcessAfterSale.unprocessed');
                case 1:
                    return this.$t('ProcessAfterSale.processing');
                case 2:
                    return this.$t('ProcessAfterSale.completed');
                default:
                    return this.$t('ProcessAfterSale.unknown');
            }
        },
        // 抽取的公共打印方法
        printContentInIframe(content) {
            const iframe = document.createElement('iframe');
            iframe.style.position = 'absolute';
            iframe.style.width = '0';
            iframe.style.height = '0';
            iframe.style.border = '0';
            document.body.appendChild(iframe);

            // 写入iframe内容用于打印
            iframe.contentDocument.write(content);
            iframe.contentDocument.close();

            iframe.onload = () => {
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
                document.body.removeChild(iframe); // 打印后删除iframe
            };
        },

        // 打印单个二维码的方法
        printQRCode(qrCodeData) {
            const content = `
      <html lang="en">
        <head>
          <style>
            body {
              text-align: center;
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
            }
            img {
              max-width: 100%;
              max-height: 100%;
            }
          </style>
        </head>
        <body>
          <img src="${qrCodeData.qrCode}" alt="QR Code" width="300" height="300">
        </body>
      </html>
    `;
            this.printContentInIframe(content);
        },

        // 批量打印二维码的方法
        batchPrintQRCodes(selectedRows) {
            let printContent = '<div>';
            selectedRows.forEach(row => {
                printContent += `
        <div class="print-page">
          <img src="${row.qrCode}" alt="QR Code" width="300" height="300">
        </div>`;
            });
            printContent += '</div>';

            const content = `
      <html>
        <head>
          <style>
            body {
              margin: 0;
              padding: 0;
              text-align: center;
            }
            .print-page {
              page-break-after: always;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
            }
            .print-page img {
              max-width: 100%;
              max-height: 100%;
            }
          </style>
        </head>
        <body>
          ${printContent}
        </body>
      </html>
    `;
            this.printContentInIframe(content);
        },

        defectCategorysData() {
            return {
                "G001": {
                    "name": this.$t("defectTypes.other"),
                    "types": {
                        "E001": this.$t("defectTypes.E001"),
                        "E002": this.$t("defectTypes.E002"),
                        "E003": this.$t("defectTypes.E003"),
                        "E004": this.$t("defectTypes.E004"),
                        "E005": this.$t("defectTypes.E005"),
                        "E006": this.$t("defectTypes.E006"),
                        "E007": this.$t("defectTypes.E007"),
                        "E008": this.$t("defectTypes.E008"),
                        "E009": this.$t("defectTypes.E009"),
                        "E010": this.$t("defectTypes.E010")
                    }
                },
                "G002": {
                    "name": "UART ERROR CODE",
                    "types": {
                        "E011": "UART-ERROR 28",
                        "E012": "UART-ERROR 25",
                        "E013": "UART-ERROR 24",
                        "E014": "UART-ERROR 21",
                        "E015": "UART-ERROR 30"
                    }
                },
                "G003": {
                    "name": "CAN ERROR CODE",
                    "types": {
                        "E016": "CAN-ERROR 37",
                        "E017": "CAN-ERROR 4",
                        "E018": "CAN-ERROR 36",
                        "E019": "CAN-ERROR 50",
                        "E020": "CAN-ERROR 3",
                        "E021": "CAN-ERROR 1",
                        "E022": "CAN-ERROR 6",
                        "E023": "CAN-ERROR 2",
                        "E024": "CAN-ERROR 9",
                        "E025": "CAN-ERROR 5",
                        "E026": "CAN-ERROR 30"
                    }
                }
            }
        },
        getLanguageMappings() {
            return {
                'zh': '简体中文',
                'en': 'English',
                // 'it': 'Italiano',
                // 'fr': 'Francais',
                // 'de': 'Deutsch',
                // 'nl': 'Dutch',
                // 'ja': '日本語',
                // 'sv': 'Svenska',
                // 'hu': 'Magyar',
                // 'pl': 'Polski',
            };
        },
        disableScroll() {
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPosition}px`;
            document.body.style.width = '100%';
        },
        enableScroll() {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.width = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        },
        truncatedText(text) {
            const maxLength = 100;
            if (!text) {
                return '';
            }
            if (text.length > maxLength) {
                return text.slice(0, maxLength) + '...';
            }
            return text;
        },
        formatDate(row, column, cellValue) {
            if (!cellValue) return '';
            const date = new Date(cellValue);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },

        async loadSubPermissions() {
            const userId = localStorage.getItem('userId'); // 从 localStorage 中获取 userId
            const cacheKey = `subPermissions_${userId}`;
            const cache = localStorage.getItem(cacheKey);
            if (cache) {
                this.subPermissions = JSON.parse(cache);
            } else {
                // 如果没有缓存，可能需要重新获取权限信息
                console.error('No user permissions found in localStorage');
                this.subPermissions = [];
            }
        },

        hasPermission(permission) {
            if (!this.subPermissions || !Array.isArray(this.subPermissions)) {
                console.error('User permissions data is invalid or not loaded');
                return false;
            }
            return this.subPermissions.includes(permission);
        },
        checkFileSize(file, maxSizeMB = 2) {
            const isWithinLimit = file.size / 1024 / 1024 < maxSizeMB;
            if (!isWithinLimit) {
                this.$message.error(`文件大小不能超过${maxSizeMB}MB!`);
                return false;
            }
            // 获取文件扩展名
            const fileExtension = file.name.split('.').pop().toLowerCase();
            // 定义允许的文件格式
            const allowedFormats = ['xml', 'bin', 'exe', 'pdf', 'avi', 'mp4', 'wmv', 'mpeg', 'flv', 'm4v', 'mov', 'rm', 'ram', 'f4v', 'jpg', 'jpeg', 'gif', 'png', 'psd', 'webm', 'xls', 'xlsx'];

            // 检查文件格式是否允许
            if (!allowedFormats.includes(fileExtension)) {
                this.$message.error(`文件格式不支持，只允许以下格式: ${allowedFormats.join(', ')}`);
                return false;
            }
            return true;
        },
        transformToTree(data) {
            let result = [];

            // 遍历对象的每个键，即每个错误代码组
            for (const groupKey in data) {
                let group = data[groupKey];
                let treeItem = {
                    id: groupKey,       // 使用组的键作为id
                    label: group.name,  // 组的名字作为显示的标签
                    children: []        // 该组的子项
                };

                // 如果组中有类型，将每种类型添加为子项
                for (const typeKey in group.types) {
                    treeItem.children.push({
                        id: typeKey,                // 类型的键作为id
                        label: group.types[typeKey] // 类型的值作为显示的标签
                    });
                }

                result.push(treeItem);
            }

            return result;
        },
        transformDefectCateforysData(defectCategorys) {
            return (defectCategorys || []).map(org => ({
                value: org.id,
                label: org.label,
                children: this.transformDefectCateforysData(org.children),
            }));
        },
    },
};